.page-parentstudent-contents{
    background-color:#FFFFFF;
    border-radius: 12px;
    padding: 12px;
    display:flex;
    box-shadow: 0px 3px 16px 0px #00000014;
}

.parent-student-conatiner{
    box-shadow: 0px 3px 16px 0px #00000014;
    border: 1px solid #EAF2FF;
    border-radius: 12px;
    padding:10px 10px 0 10px;
}

.parent-student-list{
    border-bottom: 1px solid #EAF2FF;
    height: 60px;
    align-items: center;
}
