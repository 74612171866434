.page-content{
    background-color:#FFFFFF;
    border-radius: 12px;
    padding: 32px;
    display:flex;
    // align-items: center;
};

.profilePic{
    position: relative;
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
    
    .userPic{
        min-width: 120px;
        max-width: 120px;
        min-height: 120px;
        max-height: 120px;
        border-radius: 50%;
    }
}

.border-bottom-only {
    border-bottom: 1px solid #E3E3E3;
}

.reviewProfile{
    overflow-y: scroll;
    max-height: 500px;
}

.cameraIcon{
    position: absolute;
    right: 5px;
    bottom: 5px;
};

.profileName_Heading{
    font-family: LexendDeca_Medium;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color:#1E1E1E;
    // white-space: nowrap;        /* Prevents text from wrapping */
    // overflow: hidden;           /* Hides any text that overflows */
    // text-overflow: ellipsis;
    // max-width: 100px;    
};

.review{
    font-family: Inter_SemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color:#000000;
};

.location{
    font-family: Inter_Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color:#212426;
};

.ProfileKey{
    font-family: Inter_Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color:#A2A2A2;
};

.ProfileValue{
    font-family: Inter_Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color:#1E1E1E;
};

.subjectKey{
    color: #A2A2A2;
    font-family: 'Inter_Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
};

.subjectValue{
    color:#1E1E1E;
    font-family: 'Inter_Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
};

.subjectTitle{
    font-family: 'LexendDeca_SemiBold';
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color:#1E1E1E;
    align-items: center;
}

.subjectName{
    font-family: 'Inter_Medium';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color:#1E1E1E;
}

.qualificationDetails{
    font-family: 'Inter_Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
}

.profileButton{
    font-family: 'Inter_SemiBold';
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.bioDetails{
    font-family: 'Inter_Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.boxBorder{
    max-width: 55%;
    border-bottom: 1px solid #DDDDDD;

    .pendingPill{
        padding: 8px 16px !important;
        color: var(--colors-error-success-orange, #E37B02) !important;
        border: 1px solid var(--colors-error-success-orange, #E37B02);
        background: var(--Colors-Base-highlight-orange, #FFF6EC) !important;
        font-family: 'Inter_medium';
    };
    
    .failedPill{
        padding: 8px 16px !important;
        color: #1E1E1E !important;
        border: 1px solid #1E1E1E;
        background: var(--Colors-Base-base-grey, #F5F5F5) !important;
        font-family: 'Inter_medium';
    };

    .verifiedPill{
        padding: 8px 16px !important;
        color: #0E8345 !important;
        background: #E0FFE3 !important;
        border: 1px solid #0E8345;
        font-family: 'Inter_medium';
    }
};

.teachingMaterial{
    .unSelectTeachingMaterial{
        width:120px;
        height: 42px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #DDDDDD;
        align-items: center;
        display: flex;
        justify-content: center;
        color:#A2A2A2 ;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
    .selectTeachingMaterial{
        width:120px;
        height: 42px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #911E59;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #911E59;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
}

.teacher_label{
    font-family: Inter_SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #0F172A;
};

.custom-list {
    list-style: none;
    padding:10px 0px 10px 0px;
    margin:0px;
    background-color: white;
    max-height: 200px;
    overflow-y: auto; /* Add scrollbar when content exceeds height */
    position: absolute;
    background: white;
    z-index: 999;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
};

.list-item {
    padding: 5px 5px 0 10px;
    cursor:pointer;
};

.list-item:hover{
    padding: 5px 5px 0 10px;
    cursor:pointer;
    color: #911E59 !important;
    background-color:#FFF2F8 !important ;
};
