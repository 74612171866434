.timerContainer{
    height: 54px;
    display: felx;
    align-items: center;

    .questions{
        border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        font-family: LexendDeca_Medium;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: #1E1E1E;
    };
    .exitText{
        font-family: Inter_SemiBold;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: #911E59;
        cursor: pointer;
    };

    .Time{
        font-family: LexendDeca_Medium;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: #1E1E1E;
    };

    .minute{
        font-family: LexendDeca_Light;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;       
        color: #1E1E1E;
    };
};

.questionDetails{

    .question-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .question-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
        // height: 62px;
        padding: 16px 24px 16px 24px;
        border-radius: 15px;
        cursor: pointer;
        justify-content: space-between;
        font-family: Inter_Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: #A2A2A2;
    };

    .selectQuestion-item{
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 2px solid var(--Colors-Buttons-button-primary-purple, #911E59);
        // height: 62px;
        padding: 16px 24px 16px 24px;
        border-radius: 15px;
        cursor: pointer;
        justify-content: space-between;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        color: #1E1E1E;
    };
    
    .circle {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--Colors-Icon-colors-icon-grey, #A2A2A2);
        color: #A2A2A2;
    }

    .selecterCircle {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        background-color: #911E59;
    }
    
    .question-text {
        font-size: 16px;
        color: #333;
    }

    .questionHeading{
        font-family: Inter_Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: #1E1E1E;
    };

    .options{
        font-family: LexendDeca_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;       
        color: #1E1E1E;
    }
};


