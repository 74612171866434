.searchScreen{
    padding-bottom: 150px !important;
    .search-box {
        background-color: #fff;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 2px 12px 0px #E3D3DB80;
    }
    .searchResult{
        display: flex;
        flex-wrap: wrap;

        .popular-search {
            background-color: #FFFFFF !important;
            box-shadow: 0px 2px 12px 0px #39364E1F;
            height:50px;
            border-radius: 8px;
            display: flex;
            align-items: center;
        }
    }
    .outline_btn{
        color: var(--Colors-Buttons-button-primary-purple, #911E59);
        border: 1px solid var(--Colors-Buttons-button-primary-purple, #911E59);
        border-radius: 8px;
        font-family: 'Inter_medium'
    }

}

.speakModal{
    height: 90vh;
    display: flex;
    align-items: center;
    text-align: center;
}
.close{
    position: absolute;
    top: 180px;
}

.teachersFeature{
    box-shadow: 0px 3px 16px 0px #00000014 !important;
    border-radius: 16px !important;
    border: none !important;
    padding: 12px !important;
}

.borderRed{
    border: 2px solid var(--Colors-Buttons-button-primary-purple, #911E59) !important;
}

.intoducingAiSearch{
    background-image: url('../../../../assets/images/Navbar/backgroundCutImage.png');
    // background-size: cover; /* Ensures the image covers the entire area */
    background-position: start; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-color: var(--Colors-Base-frame-base-2, #FFFFFF);
}

.testimonial-slider {
    text-align: center;
    padding: 0px;
  }
  
  .testimonial-card {
    background: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 10px;
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .testimonial-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before, .slick-prev:before, .slick-next:before {
    display: none !important;
    left:auto !important;
}

.sliderDetailsEn .slick-prev {
    position: absolute !important;
    top: -50px !important;
    right: 200px !important;
    left: auto !important;
    width: 48px !important;
    height: 48px !important;
}

.sliderDetailsEn .slick-next {
    position: absolute !important;
    top: -50px !important;
    right: 100px !important;
    display: block;
    width: 48px !important;
    height: 48px !important;
}

.sliderDetailsAr .slick-prev {
    position: absolute !important;
    top: -50px !important;
    left: 150px !important;
    width: 48px !important;
    height: 48px !important;
}

.sliderDetailsAr .slick-next {
    position: absolute !important;
    top: -50px !important;
    right: 100px !important;
    left: 10px !important;
    display: block;
    width: 48px !important;
    height: 48px !important;
}