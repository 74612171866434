.groupBox{
  border: 1px solid #DDDDDD;
  padding:24px;
  border-radius: 12px;
  // height: 100px;
  cursor: pointer;
}

.selectedGroupBox{
  border: 2px solid #911E59;
  padding:24px;
  border-radius: 12px;
  // height: 100px;
  cursor: pointer;
}

.Checkbox {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: left !important;
}

.CheckboxAr {
  text-align: right !important;
}


.form-check-label{
  font-family: LexendDeca_Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
};

.form-text.text-muted{
  font-family: Inter_Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}

.form-check-input[type=checkbox] {
  border-radius: 1.50em !important;
}

.form-check-input:checked {
  background-color: #911E59 !important; 
  border-color: #911E59 !important;
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: none !important; 
  box-shadow: none !important;
}
