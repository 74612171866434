.page-contents-view{
    background-color:#FFFFFF;
    border-radius: 12px;
    display:flex;

    .shadow{
        box-shadow: 0px 3px 16px 0px #00000014 !important;
        cursor: pointer;
    }

    .shadowSelect{
        box-shadow: 0px 3px 16px 0px #00000014 !important;
        border: 1px solid var(--Colors-Stroke-stroke-purple, #911E59) !important;
        cursor: pointer;
    }

    .badge{
        background-color: transparent !important;
        padding:0px !important;
        font-family: 'Inter_Medium' !important;
    }

    .star .MuiSvgIcon-root{
        color: #EAB308 !important;
    }

    .selectHeading{
        font-family: LexendDeca_SemiBold;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #1E1E1E;       
    }

    .backArrow{
        cursor: pointer;
    }

    .InterviewSubheading{
        font-family: Inter_Regular;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color:#A2A2A2;
    }

    .img-fluid{
        height: 120px;
        width:120px;
        border-radius: 12px;
        object-fit: cover;
    }

    .slot{
        min-width: 120px;
        height: 50.23px;
        border: 1.26px solid #6B779A1A;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4D4F51;
        padding:0 10px 0 10px; 
        cursor:pointer;
    }

    .slotSelect{
        min-width: 120px;
        height: 50.23px;
        border: 1.26px solid #6B779A1A;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Colors-Text-text-purple, #911E59);
        padding:0 10px 0 10px; 
        cursor:pointer;
        border: 1px solid var(--Colors-Stroke-stroke-purple, #911E59);
    }

    .slotList{
        display: flex;
        flex-wrap: wrap;
    }

    .TimeSlot{
        font-family: LexendDeca_SemiBold;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color:#911E59;
    }

    .selectedSlot{
        background-color:#F7F7F7;
        border-radius: 12px;
        padding: 12px 20px 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .heading{
        font-family: Inter_Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color:#4D4F51;
    }

    .success{
        font-family: Inter_Bold;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        color:#0E8345;
    }

};