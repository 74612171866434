.page-contents{
    background-color:#FFFFFF;
    border-radius: 12px;
    padding: 32px;
    display:flex;
    box-shadow: 0px 3px 16px 0px #00000014;

    .pendingPill{
        padding: 8px 16px !important;
        color: var(--colors-error-success-orange, #E37B02) !important;
        border: 1px solid var(--colors-error-success-orange, #E37B02);
        background: var(--Colors-Base-highlight-orange, #FFF6EC) !important;
        font-family: 'Inter_medium';
    };

    .failedPill{
        padding: 8px 16px !important;
        color: #1E1E1E !important;
        border: 1px solid #1E1E1E;
        background: var(--Colors-Base-base-grey, #F5F5F5) !important;
        font-family: 'Inter_medium';
    };

    .verifiedPill{
        padding: 8px 16px !important;
        color: #0E8345 !important;
        background: #E0FFE3 !important;
        border: 1px solid #0E8345;
        font-family: 'Inter_medium';
    }

    .addSubjectButton{
        background: var(--Colors-Buttons-button-primary-purple, #911E59);
        border-radius: 8px;
        height: 42px;
        color: #FFFFFF;
        padding: 14px 16px 14px 16px;
        font-family: 'Inter_SemiBold';
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        cursor: pointer;
    };

    .subjectKey{
        color: #A2A2A2;
        font-family: 'Inter_Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
    };

    .subjectValue{
        color:#1E1E1E;
        font-family: 'Inter_Medium';
        font-size: 16px;
        font-weight: 500;
        line-height: 25.6px;
    };

    .date{
        color:#911E59;
    };

    .subjectTitle{
        font-family: 'LexendDeca_SemiBold';
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color:#1E1E1E;
    }

    .boxBorder{
        max-width: 100%;
        border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
        border-radius: 8px;
        padding: 16px;

        .btn_outline{
            color: var(--Colors-Buttons-button-primary-purple, #911E59);
            border: 1px solid var(--Colors-Buttons-button-primary-purple, #911E59);
            border-radius: 8px;
            font-family: 'Inter_medium'
        };

        .subjectName{
            font-family: 'Inter_Medium';
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            color:#1E1E1E;
        }

        .btn_disabled{
            color: var(--Colors-Text-text-grey, #A2A2A2);
            border:none;
            border-radius: 8px;
            background: var(--Colors-Buttons-button-disabled, #DDDDDD);
            cursor: not-allowed;
            font-family: 'Inter_medium'
        };
    };

    .teachingMaterial{
        .unSelectTeachingMaterial{
            width:120px;
            height: 42px;
            padding: 9px 16px 9px 16px;
            border-radius: 50px;
            border: 1px solid #DDDDDD;
            align-items: center;
            display: flex;
            justify-content: center;
            color:#A2A2A2 ;
            cursor: pointer;
            font-family: 'Inter_Medium';
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
        };
        .selectTeachingMaterial{
            width:120px;
            height: 42px;
            padding: 9px 16px 9px 16px;
            border-radius: 50px;
            border: 1px solid #911E59;
            align-items: center;
            display: flex;
            justify-content: center;
            color: #911E59;
            cursor: pointer;
            font-family: 'Inter_Medium';
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
        };
    }

    .teacher_label{
        font-family: 'Inter_SemiBold';
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #0F172A;
    };

    .custom-list {
        list-style: none;
        padding:10px 0px 10px 0px;
        margin:0px;
        background-color: white;
        max-height: 200px;
        overflow-y: auto; /* Add scrollbar when content exceeds height */
        position: absolute;
        background: white;
        z-index: 999;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    };

    .list-item {
        padding: 5px 5px 0 10px;
        cursor:pointer;
    };

    .list-item:hover{
        padding: 5px 5px 0 10px;
        cursor:pointer;
        color: #911E59 !important;
        background-color:#FFF2F8 !important ;
    };

};