.boxShadow{
    box-shadow: 0px 3px 16px 0px #00000014;
    background-color: #FFFFFF;
    padding: 32px;
    border-radius: 12px;

    .imgs-fluid{
        height: 168px;
        width:168px;
        border-radius: 100%;
        max-width:168px ;
    }

    .border-right-only {
        border-right: 2px solid #212426;
    }

    .border-left-only {
        border-left: 2px solid #212426;
    }

    .border-bottom-only {
        border-bottom: 1px solid #E3E3E3;
    }

    .subjectsBox{
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
    }

    .failedPill{
        padding: 8px 16px !important;
        color: #1E1E1E !important;
        border: 1px solid #1E1E1E;
        background: var(--Colors-Base-base-grey, #F5F5F5) !important;
        font-family: 'Inter_medium';
    };
    
    .verifiedPill{
        padding: 8px 16px !important;
        color: #0E8345 !important;
        background: #E0FFE3 !important;
        border: 1px solid #0E8345;
        font-family: 'Inter_medium';
    }

    .pendingPill{
        padding: 8px 16px !important;
        color: var(--colors-error-success-orange, #E37B02) !important;
        border: 1px solid var(--colors-error-success-orange, #E37B02);
        background: var(--Colors-Base-highlight-orange, #FFF6EC) !important;
        font-family: 'Inter_medium';
    };
    
}

.starfill{
    .MuiSvgIcon-root {
        color: #EAB308 !important;
    }
}

.starUnfill{
    .MuiSvgIcon-root {
        color: #CBD5E1;
    }
}