.fileUpload{
    color: #911E59;
    cursor:pointer;
    font-family: Inter_SemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px; 
};

.profile_subheading{
    font-family: Inter_Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color: #A2A2A2;
}