.filter{
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 2px 16px 0px #00000014;
}

.filterShadow{
    .badge{
        background-color: transparent !important;
        padding:0px !important;
        font-family: 'Inter_Medium' !important;
    }

    .img-fluid{
        height: 172px;
        width:172px;
        border-radius: 12px;
        object-fit: cover;
    }
}
