.planBox{
    border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
    border-radius: 8px;
}

.plan{
    .unSelectPlan{
        height: 38px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #DDDDDD;
        align-items: center;
        display: flex;
        justify-content: center;
        color:#A2A2A2 ;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
    .selectPlan{
        height: 38px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #911E59;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #911E59;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
}