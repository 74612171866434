.menu-container{
    padding:10px 15px 10px 15px;
    min-height: 44px !important; 
    box-shadow: 0px 3px 16px 0px #00000014;
    background-color: #FFFFFF !important
};

.menu-text{
    color:#A2A2A2;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    align-items: center;
};

.menu-icon{
    cursor: pointer;
};

.title{
    color: #9FA8B5;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    font-family: 'Inter_Medium';
};

.MuiListItemIcon-root{
   min-width: 35px !important;
};

.MuiListItemButton-root{
    margin: 5px !important;
    border-radius: 8px !important;
};

.Item-text .MuiTypography-root{
    color:#A2A2A2;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'LexendDeca_Regular' !important;
};

.Item-logout .MuiTypography-root{
    color:#EE3232;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'LexendDeca_Regular' !important;
};

.active-menu-item{
    background-color: #FFF2F8 !important;
    color: #911E59 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'LexendDeca_Regular' !important;
};
  
.sidebarList {
    display: flex;
    flex-direction: column;
    min-height: 75vh; // Full viewport height to ensure the sidebar spans the whole screen
    position: relative;
    // overflow-y: auto;
  }
  
//   .sidebarList .sidebarBottom {
//     margin-top: auto; // This will push the "Logout" item to the bottom
//   }

  .sidebarList .sidebarBottom {
    margin: 0 auto;
    position: absolute;
    bottom: 0;
  }


.active-menu-item .Item-text .MuiTypography-root {
    color: #911E59 !important;
};
  
.active-menu-item .MuiListItemIcon-root {
    color: white; /* Active icon color */
};

.drawer-menu .MuiDrawer-paper{
    width:300px;
};

.submenuList {
    padding-left: 0px;
    padding: 0px;
    margin: 0px;
    width:264px;
    .active-submenu-item {
      background-color: transparent; // Adjust as needed
      color: #911E59 !important;
    }
}

.active-submenu-item .Item-text .MuiTypography-root {
    color: #911E59 !important;
};


@media (min-width: 991px) and (max-width: 1080px) {
    .drawer-menu .MuiDrawer-paper{
        width:160px !important;
    }

    .submenuList {
        width:155px !important;
    }
};

@media (min-width: 1080px) and (max-width: 1130px) {
    .drawer-menu .MuiDrawer-paper{
        width:170px !important;
    }

    .submenuList {
        width:160px !important;
    }
};

@media (min-width: 1130px) and (max-width: 1230px) {
    .drawer-menu .MuiDrawer-paper{
        width:180px !important;
    }

    .submenuList {
        width:160px !important;
    }
};

@media (min-width: 1230px) and (max-width: 1330px) {
    .drawer-menu .MuiDrawer-paper{
        width:190px !important;
    }

    .submenuList {
        width:190px !important;
    }
};

@media (min-width: 1230px) and (max-width: 1330px) {
    .drawer-menu .MuiDrawer-paper{
        width:200px !important;
    }

    .submenuList {
        width:200px !important;
    }
};

@media (min-width: 1330px) and (max-width: 1430px) {
    .drawer-menu .MuiDrawer-paper{
        width:210px !important;
    }

    .submenuList {
        width:195px !important;
    }
};

@media (min-width: 1430px) and (max-width: 1530px) {
    .drawer-menu .MuiDrawer-paper{
        width:220px !important;
    }

    .submenuList {
        width:220px !important;
    }
};

@media (min-width: 1530px) and (max-width: 1630px) {
    .drawer-menu .MuiDrawer-paper{
        width:250px !important;
    }

    .submenuList {
        width:250px !important;
    }
};

@media (min-width: 1630px) {
    .drawer-menu .MuiDrawer-paper{
        width:265px !important;
    }

    .submenuList {
        width:264px !important;
    }
};


