
.questionAnswers{

    .question-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .question-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px solid var(--Colors-Stroke-stroke, #EE3232);
        min-height: 62px;
        padding: 16px 24px 16px 24px;
        border-radius: 15px;
        cursor: pointer;
        justify-content: space-between;
        font-family: Inter_Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: #EE3232;
        background: #FFF8F8;
    };

    .selectQuestion-item{
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px solid var(--Colors-Stroke-stroke-green, #0E8345);
        background: var(--colors-error-success-green-light, #F0FFF1);
        min-height: 62px;
        padding: 16px 24px 16px 24px;
        border-radius: 15px;
        cursor: pointer;
        justify-content: space-between;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        color: #0E8345;
    };
    
    .circle {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--Colors-Icon-colors-icon-grey, #EE3232);
        color: #EE3232;
    }

    .selecterCircle {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0E8345;
        border: 1px solid #0E8345;
        background: var(--Colors-Text-text-success, #F0FFF1);
    }
    
    .question-text {
        font-size: 16px;
        color: #333;
    }

    .questionHeading{
        font-family: Inter_Regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: #1E1E1E;
    };
};

.options{
    font-family: LexendDeca_Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;       
    color: #1E1E1E;
}

.summaryHeading{
    font-family: Inter_Regular;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color:#A2A2A2; 
};

.summaryResults{
    font-family: Inter_Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;  
};

.black-color{
    color:#1E1E1E;
};

.green-color{
    color:#0E8345;
};

.orange-color{
    color:#EE3232;
};

.resultHeading{
    font-family: LexendDeca_Medium;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
};

.resultSubheading{
    font-family: Inter_Regular;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    color:#A2A2A2;
};

.ResultDetails{
    border-bottom: 1px solid var(--Colors-Stroke-stroke, #DDDDDD)
};

.Test{
    height: 54px;
    width: 174px;
    border-radius: 8px;
    padding:12px 24px 12px 24px;
    border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
    font-family: LexendDeca_Medium;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #1E1E1E;
};


