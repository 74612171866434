.container{

.teachingMaterial{
    .unSelectTeachingMaterial{
        width:120px;
        height: 42px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #DDDDDD;
        align-items: center;
        display: flex;
        justify-content: center;
        color:#A2A2A2 ;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
    .selectTeachingMaterial{
        width:120px;
        height: 42px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #911E59;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #911E59;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
}

    .teacher_label{
        font-family: Inter_SemiBold;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #0F172A;
    };

    .custom-list {
        list-style: none;
        padding:10px 0px 10px 0px;
        margin:0px;
        background-color: white;
        max-height: 200px;
        overflow-y: auto; /* Add scrollbar when content exceeds height */
        position: absolute;
        background: white;
        z-index: 999;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    };

    .list-item {
        padding: 5px 5px 0 10px;
        cursor:pointer;
    };

    .list-item:hover{
    padding: 5px 5px 0 10px;
    cursor:pointer;
    color: #911E59 !important;
    background-color:#FFF2F8 !important ;
    };

};