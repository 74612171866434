.container{
    min-height: 100vh;
    position: relative;
};

.heading{
    font-family: 'LexendDeca_SemiBold';
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
};

.heading2{
    font-family: 'LexendDeca_SemiBold';
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
    color:#000000
}

.heading5{
    font-family: 'LexendDeca_SemiBold';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color:#000000
}

.heading6{
    font-family: 'LexendDeca_Medium';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color:#000000
}

.paragraph1{
    font-family: 'Inter_Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.layout{
    font-family: 'LexendDeca_SemiBold';
    font-size: 48px;
    font-weight: 600;
    line-height: 72px; 
    color: #000000; 
};

.layout_title{
    font-family: 'Inter_Medium';
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    color: #808894;
};

.layout_text{
    font-family: 'Inter_Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #1E1E1E;
};

.signInWrap{
    border: 1px solid #E2E8F0;
    padding:12px, 16px, 12px, 16px;
    border-radius: 8px;
    height: 56px;
    align-items: center;
    display: flex;
    justify-content: center;
};

.resend{
    cursor: pointer;
    font-family: 'Inter_SemiBold';
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color:#911E59;
};

.resend.disable{
    cursor:not-allowed ;
};

.resend.forgot{
    font-size: 18px;
};

.sucessfulSubheading{
    font-family: 'Inter_Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color:#A2A2A2;
}

.already_account{
    font-family: 'Inter_Regular';
    font-size: 16px;
    font-weight: 400;
    color:  #7C8290;
    line-height: 25.6px;
};

.signup{
    font-family: 'Inter_Bold';
    font-size: 16px;
    font-weight: 700;
    color: #0F172A;
    line-height: 16px;
    cursor: pointer;
    text-decoration: underline;
};

.footer {
    position: absolute;
    width: 100%;
    bottom: 80px;

    .button{
        height: 64px !important;
        background-color: #911E59 !important;
        color: #FFFFFF !important;
        outline: none !important;
        border: none !important;
        border-radius: 8px !important;
        font-family: 'Inter_SemiBold';
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
    }
};


.emailHeading{
    font-family: 'Inter_Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #0E0F10;
};

.emailSubHeading{
    font-family: 'Inter_Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: center;
    color: #0E0F10;
};

.buttonSelector{
    font-family: 'Inter_SemiBold';
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
}

.dashboard_container{
    background-color: #F7F5EF;
    height: 100vh;
    overflow:auto;
};

.main-content{
    background-color: #FFFFFF;
    border-radius: 12px;
    width:100%; 
};

.cursor{
    cursor: pointer;
}

.bc-FFFFFF{
    background-color: #FFFFFF;
}

.bc-F8F8F8{
    background-color: #F8F8F8;
}

.bc-FDFCFA{
    background-color: #FDFCFA;
}

.bc-F7F5EF{
    background-color: #F7F5EF
}

.fs-14 {
    font-size: 14px;
}
.fw-400 {
    font-weight: 400;
}
.fc-60656C{
    color:#60656C;
}
.fc-000000{
    color:#000000;
}
.fc-525252{
    color:#525252;
}
 
.ff-InterRegular{
    font-family: 'Inter_Regular';
}
.ff-LexendDecaRegular{
    font-family: 'LexendDeca_Regular';
}
.ff-InterSemiBold{
    font-family: 'Inter_SemiBold';
}

.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-32 {
    font-size: 32px;
}
.fs-40 {
    font-size: 40px;
}
.fs-48 {
    font-size: 48px;
}
.fw-500 {
    font-weight: 500;
}
.fc-1E1E1E{
    color:#1E1E1E;
}
.fc-A2A2A2{
    color:#A2A2A2;
}
.fc-375D84{
    color:#375D84;
}
.fc-293169{
    color:#293169;
}
.fc-334155{
    color:#334155;
}
.fc-9FA8B5{
    color:#9FA8B5;
}
.fc-A3A3A3{
    color:#A3A3A3;
}
.fc-4D4F51{
    color:#4D4F51;
}
.fc-911E59{
    color:#911E59;
}
.fc-212426{
    color:#212426;
}
.fc-8E9193{
    color:#8E9193;
}
.fc-0F172A{
    color:#0F172A;
}
.fc-101212{
    color:#101212;
}
.fc-817E7E{
    color:#817E7E;
}
.fc-445658{
    color:#445658;
}
.fc-282938{
    color:#282938;
}
.bc-FFFFFF{
    color:#FFFFFF;
}
.fc-mixColor{
    background: linear-gradient(278.5deg, #911E59 -15.16%, #375D84 102.64%);
    background-clip: text; 
    color: transparent;
}
.minHeight-600{
    min-height: 600px;
}
.ff-InterMedium{
    font-family: 'Inter_Medium';
}
.ff-LexendDecaSemibold{
    font-family: 'LexendDeca_SemiBold';
}
.ff-LexendDecaMedium{
    font-family: 'LexendDeca_Medium';
}
.ff-InterBold{
    font-family: 'Inter_Bold';
}

.border-left-only {
    border-left: 2px solid #212426;
}

.Popover{

    .MuiSvgIcon-root {
        color:#1E1E1E;
    };

    .MuiPaper-root.MuiPopover-paper {
        box-shadow: 0px 4px 8px 0px #DBE0E980 !important;
    }
    
    .group {
        color: #1E1E1E !important;
    }

    .group:hover {
        background: #FFF2F8 !important;
    }

    .group:hover .MuiSvgIcon-root, /* To target the MUI icon */
    .group:hover p {
        color: #911E59 !important;
    }
}

.backgroundImage{
    background-image: url('../images/Navbar/backgroundSearchImage.svg');
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-color: var(--Colors-Base-frame-base-2, #F7F5EF);
    // height: 780px;
}

.backgroundImage2{
    background-image: url('../images/Navbar/backgroundSearchImage2.svg');
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-color: var(--Colors-Base-frame-base-2, #F7F5EF);
    // height: 780px;
}

.button{
    background-color: #911E59 !important;
    color:#FFFFFF !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    height: 50px !important;
    border-radius: 12px !important;
    font-family: Inter_SemiBold !important;
};

.disableButton{
    background-color: #DDDDDD !important;
    color:#A2A2A2 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    height: 50px !important;
    border-radius: 12px !important;
    font-family: Inter_SemiBold !important;
};

.button-outline{
    background-color: transparent !important;
    border:1px solid #911E59 !important;
    color:#911E59 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    height: 50px !important;
    border-radius: 12px !important;
    font-family: Inter_SemiBold !important;
};

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1100px) {
    .footer {
        position:unset;
        width:auto;
    }
};

@media (max-height: 850px) {
    .footer {
        position:unset;
        width:auto;
    }
};
