.page-content{
    background-color:#FFFFFF;
    border-radius: 12px;
    padding: 32px;
    display:flex;
    // align-items: center;
};

.selected-date {
    border: 1px solid #911E59 !important;
    background-color: #FFF2F8 ;
};

    .eventEn .rbc-event, .rbc-day-slot .rbc-background-event {
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        margin: 0;
        padding: 2px 5px;
        background-color: transparent;
        border-radius: 5px;
        color: #911E59;
        cursor: pointer;
        width: 100%;
        text-align: left;
    }

    .eventAr .rbc-event, .rbc-day-slot .rbc-background-event {
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        margin: 0;
        padding: 2px 5px;
        background-color: transparent;
        border-radius: 5px;
        color: #911E59;
        cursor: pointer;
        width: 100%;
        text-align: right;
    }

.eventDot{
    height: 8px !important;
    width:8px !important;
    background: var(--Colors-Purple-Purple-300, #B56890);
    border-radius: 50%;
};

.eventDetails{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
};

.eventTitle{
    font-family: 'Inter_SemiBold' !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color:#A2A2A2;
    // color:#911E59;
    display: flex;
};

.RepeatOn{
    font-family: 'Inter_SemiBold' !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color:#1E1E1E;
}

.MuiSvgIcon-root {
    color:#911E59;
};

.Mui-selected {
    color:#FFFFFF !important;
    background-color: #911E59 !important;
};

.scheduleSlot{
    height: 600px;
    overflow: auto;
}

.eventScheduleTitle{
    display: flex;
    font-family: 'Inter_Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color:#1E1E1E;
};

.eventDateTime{
    font-family: 'Inter_Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--Colors-Text-text-grey, #A2A2A2);
};

.schdeuleHeading{
    font-family: Inter_Bold;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color:"#1E1E1E";
};

.rbc-show-more {
    background: #00000099;
    z-index: 4;
    height: auto;
    color: #FFFFFF;
    padding:0px 4px 0px 4px;
    border-radius: 4px;
    font-family: 'Inter_Medium' !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    width:98%;
    text-align: start;
};

.rbc-show-more:hover {
    background: #00000099;
    z-index: 4;
    height: auto;
    color: #FFFFFF;
    padding:0px 4px 0px 4px;
    border-radius: 4px;
    font-family: 'Inter_Medium' !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
};

.eventTime{
    font-family: 'Inter_Medium' !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color:#333333;
};

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: transparent;
};

.cancelButton{
    border: 1px solid var(--Colors-Buttons-button-primary-purple, #911E59) !important;
    border-radius: 8px !important;
    font-family: 'Inter_SemiBold' !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    color:#911E59 !important;
    height: 50px !important;
};

.eventListSchdeule{
    border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
    // height: 65px;
    padding: 8px 10px 8px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
};

.endRepeatDate{
    .unSelectEndRepeatDate{
        height: 42px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #DDDDDD;
        align-items: center;
        display: flex;
        justify-content: center;
        color:#A2A2A2 ;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
    .selectEndRepeatDate{
        height: 42px;
        padding: 9px 16px 9px 16px;
        border-radius: 50px;
        border: 1px solid #911E59;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #911E59;
        cursor: pointer;
        font-family: Inter_Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    };
}

.weekItem{
    width: 48px;
    height: 48px;
    border-radius: 50px; 
    border: 1px solid var(--Colors-Stroke-stroke, #DDDDDD);
    font-family: 'Inter_Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Light-Theme-Text-Disabled-Text, #A0A0A0);
    cursor: pointer;
}

.weekItemSelected{
    width: 48px;
    height: 48px;
    border-radius: 50px; 
    background: var(--Colors-Buttons-button-primary-purple, #911E59);
    font-family: 'Inter_Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;
}

.eventViewScheduleTitle{
    font-family: 'Inter_Bold';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #404343;
}

.eventViewDateTime{
    font-family: 'Inter_Medium';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color:#1E1E1E;
}

.grey{
    color:#A2A2A2;
    font-family: 'Inter_SemiBold';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
}


.group {
    color: #1E1E1E !important;
}

.group:hover {
    background: #FFF2F8 !important;
}

.group:hover .MuiSvgIcon-root, /* To target the MUI icon */
.group:hover p {
    color: #911E59 !important;
}
