@tailwind base;
@tailwind components;
@tailwind utilities;


/* ////////////////////////// LexaDexa Font ////////////////// */

@font-face {
    font-family: LexendDeca_Black;
    src:url('./assets/fonts/Lexend/LexendDeca-Black.ttf');
}
@font-face {
    font-family: LexendDeca_Bold;
    src:url('./assets/fonts/Lexend/LexendDeca-Bold.ttf');
}
@font-face {
    font-family: LexendDeca_ExtraBold;
    src:url('./assets/fonts/Lexend/LexendDeca-ExtraBold.ttf');
}
@font-face {
    font-family: LexendDeca_ExtraLight;
    src:url('./assets/fonts/Lexend/LexendDeca-ExtraLight.ttf');
}
@font-face {
    font-family: LexendDeca_Light;
    src:url('./assets/fonts/Lexend/LexendDeca-Light.ttf');
}
@font-face {
    font-family: LexendDeca_Medium;
    src:url('./assets/fonts/Lexend/LexendDeca-Medium.ttf');
}
@font-face {
    font-family: LexendDeca_Regular;
    src:url('./assets/fonts/Lexend/LexendDeca-Regular.ttf');
}
@font-face {
    font-family: LexendDeca_SemiBold;
    src:url('./assets/fonts/Lexend/LexendDeca-SemiBold.ttf');
}
@font-face {
    font-family: LexendDeca_Thin;
    src:url('./assets/fonts/Lexend/LexendDeca-Thin.ttf');
}

/* ////////////////////////// LexaDexa Font ////////////////// */


/* ////////////////////////// Inter Font ////////////////// */

@font-face {
    font-family: Inter_Black;
    src:url('./assets/fonts/Inter/Inter_28pt-Black.ttf');
}
@font-face {
    font-family: Inter_BlackItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-BlackItalic.ttf');
}
@font-face {
    font-family: Inter_Bold;
    src:url('./assets/fonts/Inter/Inter_28pt-Bold.ttf');
}
@font-face {
    font-family: Inter_BoldItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-BoldItalic.ttf');
}
@font-face {
    font-family: Inter_ExtraBold;
    src:url('./assets/fonts/Inter/Inter_28pt-ExtraBold.ttf');
}
@font-face {
    font-family: Inter_ExtraBoldItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-ExtraBoldItalic.ttf');
}
@font-face {
    font-family: Inter_ExtraLight;
    src:url('./assets/fonts/Inter/Inter_28pt-ExtraLight.ttf');
}
@font-face {
    font-family: Inter_ExtraLightItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-ExtraLightItalic.ttf');
}
@font-face {
    font-family: Inter_Italic;
    src:url('./assets/fonts/Inter/Inter_28pt-Italic.ttf');
}
@font-face {
    font-family: Inter_Light;
    src:url('./assets/fonts/Inter/Inter_28pt-Light.ttf');
}
@font-face {
    font-family: Inter_LightItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-LightItalic.ttf');
}
@font-face {
    font-family: Inter_Medium;
    src:url('./assets/fonts/Inter/Inter_28pt-Medium.ttf');
}
@font-face {
    font-family: Inter_MediumItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-MediumItalic.ttf');
}
@font-face {
    font-family: Inter_Regular;
    src:url('./assets/fonts/Inter/Inter_28pt-Regular.ttf');
}
@font-face {
    font-family: Inter_SemiBold;
    src:url('./assets/fonts/Inter/Inter_28pt-SemiBold.ttf');
}
@font-face {
    font-family: Inter_SemiBoldItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-SemiBoldItalic.ttf');
}
@font-face {
    font-family: Inter_Thin;
    src:url('./assets/fonts/Inter/Inter_28pt-Thin.ttf');
}
@font-face {
    font-family: Inter_ThinItalic;
    src:url('./assets/fonts/Inter/Inter_28pt-ThinItalic.ttf');
}

/* ////////////////////////// Inter Font ////////////////// */
