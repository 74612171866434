
  .react-tel-input .form-control {
    // border: 1px solid #ced4da;
    border-radius: 4px;
    height: 50px;
    padding-left: 60px;
  }
  
  .react-tel-input .flag-dropdown {
    // border: 1px solid #ced4da;
    border-radius: 8px;
    background-color: #fff;
    border: none !important;
  };

  .react-tel-input .form-control:focus {
    outline: none;
    box-shadow: none; /* Remove any default shadow */
    border-color: #911E59; /* Optional: Add custom border color on focus */
  }

  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 0px;
    border-radius:8px;
}

  .phone-number-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 1rem;
  }
  
  .phone-label {
    font-family: Inter_SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
  }
  
  .phone-input-wrapper {
    display: flex;
    align-items: center;
  }
  
  .country-code-input {
    margin-right: 20px; /* Space between country code and phone number */
  }
  
  .form-control.custom-country-input{
    width: 150px; /* Adjust width to fit the country code */
    border-radius: 8px;
    margin-left: 0px;
  }

  .phone-en-react .react-tel-input .selected-flag .flag {
    margin-left: 20px;
  }

  .phone-ar-react .react-tel-input .selected-flag .flag {
    margin-right: 25px;
  }

  .react-tel-input .selected-flag .flag {
    margin-left: 20px;
  }

  .custom-phone-input {
    width: 100%; /* Adjust width to fit the phone number */
    padding: 10px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    height: 50px;
  }

  .custom-phone-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #911E59;
  }
  
  // .custom-phone-input.invalid {
  //   border-color: red; /* Highlight input in red if invalid */
  // }
  
  .error-message {
    font-size: 16px;
    color: #EE3232;
    line-height: 24px;
    font-weight: 400;
    font-family: Inter_Regular;
    margin-top: 5px;
  }

  .phone-number-input{
    width:100% !important;
    height: 50px;
  }