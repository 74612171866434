.page-categoryManagement-contents{
    background-color:#FFFFFF;
    border-radius: 12px;
    display:flex;
    box-shadow: 0px 3px 16px 0px #00000014;

    .categoryList{
        max-height: 600px;
        overflow-y: auto;
    }

    .addSubjectButton{
        background: var(--Colors-Buttons-button-primary-purple, #911E59);
        border-radius: 8px;
        height: 42px;
        color: #FFFFFF;
        padding: 14px 16px 14px 16px;
        font-family: 'Inter_SemiBold';
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        cursor: pointer;
    };
}